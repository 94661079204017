<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="spinner"
    />
    <v-layout wrap justify-center>
      <v-flex xs12 lg11 pt-lg-6>
        <v-card class="mx-auto elevation-2 pa-6" align="center" outlined>
          <!----------------------filters---------------------------->
          <v-layout wrap>
            <v-flex xs12 lg6 pb-2 class="nsbold text-left"
              ><span style="font-size: 20px">Leave Extention</span></v-flex
            >
            <v-flex xs12 md6 lg2>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="fromDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fromDate"
                    label="From"
                    outlined
                    readonly
                    hide-details
                    dense
                    clearable
                    class="rounded-0"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fromDate"
                  no-title
                  scrollable
                  @change="$refs.menu.save(fromDate)"
                >
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 md6 lg2 pl-md-4>
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="toDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="toDate"
                    label="To"
                    hide-details
                    readonly
                    outlined
                    dense
                    clearable
                    class="rounded-0"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="toDate"
                  no-title
                  scrollable
                  @change="$refs.menu2.save(toDate)"
                >
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 md6 lg2 pl-lg-4>
              <v-text-field
                v-model="keyword"
                dense
                hide-details
                label="Search : Ad.no/Name"
                outlined
                clearable
              ></v-text-field>
            </v-flex>
            <!-- <v-flex xs12 md6 lg3 pl-md-4 pt-md-6>
             <v-select
                    :items="issueStatus "
                    v-model="cvalue"
                    label="Status"
                    outlined
                    hide-details
                    dense
                  ></v-select>
          </v-flex> -->
            <!--------------------------list------------------------>
          </v-layout>
          <v-layout wrap justify-center>
            <v-flex xs12 v-if="outpass">
              <v-layout wrap class="nsbold" style="font-size: 16px">
                <v-flex xs12 v-if="outpass.length > 0">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">No.</th>
                          <th class="text-left">Admission No</th>
                          <th class="text-left">Name</th>
                          <th class="text-left">Class</th>
                          <th class="text-left">From Date</th>
                          <th class="text-left">To Date</th>
                          <th class="text-left">Extended Date</th>
                          <th class="text-left">Actions</th>
                          <th class="text-left">View</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, i) in outpass" :key="i">
                          <td>
                            <span v-if="page == 1">
                              {{ i + 1 }}
                            </span>
                            <span v-else>
                              {{ i + 1 + 20 * (page - 1) }}
                            </span>
                          </td>
                          <td>
                            {{ item.studentid.admissionNo }}
                          </td>
                          <td>
                            <router-link
                              style="text-decoration: none; color: black"
                              :to="'/studentOutpass?id=' + item.studentid._id"
                            >
                              {{ item.studentid.name }}
                            </router-link>
                          </td>
                          <td>
                            <span v-if="item.studentid">
                              {{ item.studentid.Class }} :
                              {{ item.studentid.division }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                          <td>
                            <span v-if="item.oldFromDate">
                              {{ formatDate(item.oldFromDate) }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                          <td>
                            <span v-if="item.oldToDate">
                              {{ formatDate(item.oldToDate) }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                          <td>
                            <span v-if="item.toDate">
                              {{ formatDate(item.toDate) }}
                            </span>
                            <span v-else>-</span>
                          </td>
                          <!-- <td>
                            <span v-if="item.leaveStatus=='wApproved'">
                              Warden Approved
                            </span> -->
                          <!-- <span v-else-if="item.leaveStatus=='rejected'">
                             Rejected
                            </span> -->
                          <!-- <span v-else>-</span>
                          </td> -->

                          <td>
                            <div v-if="item.leaveStatus == 'Pending'">
                              <!-- <v-btn outlined small @click="approveData(item._id)"> -->
                              <span>
                                <v-icon
                                  color="green"
                                  title="Approve Request"
                                  @click="
                                    (approvedialoge = true), (curId = item._id)
                                  "
                                >
                                  mdi-check-all
                                </v-icon>
                              </span>
                              <span
                                ><v-icon
                                  color="red"
                                  title="Reject Request"
                                  @click="
                                    (rejectdialoge = true), (curId = item._id)
                                  "
                                >
                                  mdi-close
                                </v-icon></span
                              >
                            </div>
                            <div v-else>
                              <span>-</span>
                              <!-- <span>   
                              <v-btn small disabled text outlined plain>
                                Approved
                              </v-btn>
                              </span>
                              <span><v-btn small disabled text outlined plain>
                                Rejected
                              </v-btn></span> -->
                            </div>
                          </td>
                          <td>
                            <v-dialog
                              v-model="item.dialog"
                              persistent
                              max-width="590"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  style="cursor: pointer; color: #766bc0"
                                  >mdi-eye</v-icon
                                >
                              </template>
                              <v-card>
                                <v-layout wrap>
                                  <v-flex xs12 pa-4>
                                    <v-layout wrap>
                                      <v-flex xs12 sm6 text-sm-left>
                                        <span
                                          class="nsbold"
                                          style="font-size: 16px"
                                        >
                                          Name: {{ item.leaveid.name }}
                                        </span>
                                      </v-flex>
                                      <v-flex xs12 sm6 text-sm-right>
                                        <span
                                          class="nsbold"
                                          style="font-size: 16px"
                                        >
                                          Admission No:
                                          {{ item.leaveid.admissionNo }}
                                        </span>
                                      </v-flex>
                                    </v-layout>
                                    <v-divider></v-divider>
                                    <v-layout wrap pa-md-4>
                                      <v-flex xs12 md6 text-md-left>
                                        <span
                                          class="nsregular"
                                          style="font-size: 14px"
                                        >
                                          <b>No.of days:</b>
                                          {{ item.numberOfDays }}
                                        </span>
                                      </v-flex>
                                      <v-flex xs12 md6 text-md-right>
                                        <span
                                          v-if="item.purpose"
                                          class="nsregular"
                                          style="font-size: 14px"
                                        >
                                          <b>Purpose:</b>
                                          {{ item.purpose }}
                                        </span>
                                      </v-flex>
                                      <v-flex xs12 md6 text-md-left>
                                        <span
                                          class="nsregular"
                                          style="font-size: 14px"
                                        >
                                          <b>From:</b>
                                          {{ formatDate(item.oldFromDate) }}
                                        </span>
                                      </v-flex>
                                      <v-flex xs12 md6 text-md-right>
                                        <span
                                          class="nsregular"
                                          style="font-size: 14px"
                                        >
                                          <b>To:</b>
                                          {{ formatDate(item.oldToDate) }}
                                        </span>
                                      </v-flex>
                                      <v-flex xs12 md6 text-md-left>
                                        <span
                                          class="nsregular"
                                          style="font-size: 14px"
                                        >
                                          <b>Extended Date:</b>
                                          {{ formatDate(item.toDate) }}
                                        </span>
                                      </v-flex>

                                      <v-flex xs12 md6 text-md-right>
                                        <span
                                          class="nsregular"
                                          style="font-size: 14px"
                                        >
                                          <b>Status:</b> {{ item.leaveStatus }}
                                        </span>
                                      </v-flex>
                                      <!-- <v-flex xs12 md6 text-md-left>
                                        <span
                                          class="nsregular"
                                          style="font-size: 14px"
                                        >
                                          <b>Extended Date:</b> {{ item.purpose }}
                                        </span>
                                      </v-flex> -->
                                    </v-layout>
                                  </v-flex>
                                </v-layout>

                                <v-card-actions>
                                  <v-spacer></v-spacer>

                                  <v-btn
                                    color="#766BC0"
                                    text
                                    @click="item.dialog = false"
                                  >
                                    Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-flex>
                <v-flex v-else xs12 align-self-center text-center pa-2>
                  <span class="nsregular">Currently no item found!</span>
                </v-flex>
              </v-layout>
              <v-pagination
                small
                color="#766BC0"
                total-visible="7"
                v-model="page"
                :length="Pagelength"
              >
              </v-pagination>
            </v-flex>
            <v-dialog width="400px" v-model="approvedialoge">
              <v-card width="400px">
                <v-toolbar dark color="#766BC0" dense flat class="body-2">
                  <v-toolbar-title> Confirm </v-toolbar-title>
                </v-toolbar>
                <v-card-text class="pa-4 black--text"
                  >Are you sure you want to Approve?
                </v-card-text>
                <v-card-actions class="pt-3">
                  <v-spacer></v-spacer>
                  <v-btn
                    color="grey"
                    text
                    class="body-2 font-weight-bold"
                    @click="approvedialoge = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    color="primary"
                    class="body-2 font-weight-bold"
                    outlined
                    @click="approveData()"
                    >Approve</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog width="400px" v-model="rejectdialoge">
              <v-card width="400px">
                <v-toolbar dark color="#766BC0" dense flat class="body-2">
                  <v-toolbar-title> Confirm </v-toolbar-title>
                </v-toolbar>
                <v-card-text class="pa-4 black--text"
                  >Enter reject reason
                </v-card-text>
                <v-flex xs12 px-4 py-2>
                  <v-text-field v-model="reson" outlined dense></v-text-field>
                </v-flex>

                <v-card-actions class="pt-3">
                  <v-spacer></v-spacer>
                  <v-btn
                    color="grey"
                    text
                    class="body-2 font-weight-bold"
                    @click="rejectdialoge = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    color="primary"
                    class="body-2 font-weight-bold"
                    outlined
                    @click="rejectData()"
                    >Reject</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-snackbar
              :timeout="3000"
              :value="true"
              absolute
              centered
              color="red"
              v-model="showsnackbar"
            >
              <span style="font-size: 20px"> {{ msg }}</span>
            </v-snackbar>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      page: 1,
      Pagelength: 0,
      curId: null,
      dialoge: false,
      approvedialoge: false,
      rejectdialoge: false,
      reson: null,
      menu: false,
      menu2: false,
      fromDate:null,
      toDate:null,
      // fromDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      //   .toISOString()
      //   .substr(0, 10),
      // toDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      //   .toISOString()
      //   .substr(0, 10),
      keyword: null,
      msg: null,
      //    issueStatus : ["All", "Out", "Issued", "In"],
      //   cvalue: "All",
      showsnackbar: false,
      ServerError: false,
      appLoading: false,
      outpass: null,
    };
  },
  mounted() {
    if (this.fromDate) {
      var dt = new Date();

      // var day=dt.getDate()
      dt.setDate(dt.getDate() - 30 + 1);
      this.fromDate = dt.toISOString().substr(0, 10);
    }
    if (this.from) {
      var dlt = new Date();

      // var day=dlt.getDate()
      dlt.setDate(dlt.getDate() - 30 + 1);
      this.fromDate = dlt.toISOString().substr(0, 10);
    }
    this.getData();
    // this.getData2();
  },
  watch: {
    page() {
      // this.getData();
      this.getData();
    },
    fromDate() {
      if (this.page > 1) this.page = 1;
      this.getData();
    },
    toDate() {
      if (this.page > 1) this.page = 1;

      this.getData();
    },
    keyword() {
      if (this.page > 1) this.page = 1;
      // this.getData();
      this.getData();
    },
    // cvalue() {
    //   if(this.page>1)
    //   this.page=1
    //   this.getData();
    // },
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/extendleave/pending/getalllist",

        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.id,
          page: this.page,
          limit: 20,
          //   issueStatus : a,
          keyword: this.keyword,
          from: this.fromDate,
          to: this.toDate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.outpass = response.data.data;

            //    for(let i=0; i<this.outpass.length; i++)
            //    {
            //      if(this.outpass[i].issueStatus == "Active")
            //      {
            //         this.outpass[i].issueStatus = "Issued";
            //      }
            //    }

            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
    approveData() {
      this.appLoading = true;
      axios({
        method: "post",
        url: "/extendleave/vcapprove",

        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.curId,
          page: this.page,
          limit: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.approvedialoge = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.outpass = response.data.data;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          } else {
            this.msg = response.data.msg;
            this.approvedialoge = false;
            this.showsnackbar = true;
          }
          this.getData();
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
    rejectData() {
      this.appLoading = true;
      axios({
        method: "post",
        url: "/extendleave/reject/viceprincipal",

        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.curId,
          rejectReason: this.reson,
          page: this.page,
          limit: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.rejectdialoge = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.outpass = response.data.data;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
          this.getData();
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
    // getData2() {
    //   this.appLoading = true;
    //   axios({
    //     method: "get",
    //     url: "/leaverequests/approved",

    //     headers: {
    //       token: localStorage.getItem("token"),
    //     },
    //     data: {
    //       id: this.$route.query.id,
    //       page: this.page,
    //       limit: 20,
    //       //   issueStatus : a,
    //       keyword: this.keyword,
    //       from: this.fromDate,
    //       to: this.toDate,
    //     },
    //   })
    //     .then((response) => {
    //       this.appLoading = false;
    //       if (response.data.status == true) {
    //         this.outpass = response.data.data;

    //         //    for(let i=0; i<this.outpass.length; i++)
    //         //    {
    //         //      if(this.outpass[i].issueStatus == "Active")
    //         //      {
    //         //         this.outpass[i].issueStatus = "Issued";
    //         //      }
    //         //    }

    //         this.Pagelength = Math.ceil(response.data.totalLength / 20);
    //       }
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       console.log(err);
    //       this.ServerError = true;
    //     });
    // },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " , " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;

      return strTime;
    },
  },
};
</script>
